.fc .fc-event {
  background: none !important;
  border: none !important;
}

.fc .fc-event img.event-image {
  width: 100%;
  height: auto;
  display: block;
  background: none !important;
  border: none !important;
}
